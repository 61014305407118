import { useEffect, useState, useMemo } from "react";
import { forceCheck } from "react-lazyload";
import StickyBox from "react-sticky-box";

import { isCrawlerBot } from "../../context/helpers";
import { useFiltersAndSorting, useOrderProducts } from "../../hooks/useFiltersAndSorting";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { ComponentType } from "../../constants/ComponentType";

import SortsCollection from "../../components/CollectionPage/SortsCollection";
import FiltersCollection from "../../components/CollectionPage/FiltersCollection";
import ProductCollection from "../../components/CollectionPage/ProductCollection";
import { ALButton } from "../../components/ALComponents";

import * as Styles from "./PdpAdsCollection/PdpAdsCollection.module.scss";

const DEFAULT_ROWS_BY_PAGES = 12;

function PdpAdsCollection({
  productsInCollection,
  productData,
  collectionHandle,
  shopifyPrices,
  collection,
}) {
  const { isMobile } = useDeviceDetect();
  const dict = useLocalizedSentenceDict();
  const [page, setPage] = useState(1);
  const isRobot = isCrawlerBot();
  const { sortingType, setSortingType, activeFilters, setActiveFilters, products, filterStats } =
    useFiltersAndSorting(
      // Exclude current product from list
      productsInCollection.filter((p) => p.contentful.node.handle !== productData.handle),
      {
        priceKey: "fullPrice",
      }
    );
  const shouldOrderProducts = useOrderProducts(activeFilters, sortingType);
  useEffect(() => setPage(1), [activeFilters]);
  useEffect(() => {
    // fix lazyload on products when refresh
    setTimeout(() => forceCheck(), 300);
  }, [products]);

  const rowsPerPage = collection.rowsPerPage || DEFAULT_ROWS_BY_PAGES;
  const nbProductsByRow = isMobile ? 2 : 3;
  const isLastProductDisplayed =
    rowsPerPage * page * nbProductsByRow >= products.filter((p) => p.contentful.node).length;

  const pricesByProduct = useMemo(() => {
    if (!shopifyPrices) {
      return {};
    }

    return shopifyPrices.edges.reduce((acc, price) => {
      if (!acc[price.node.handle]) {
        acc[price.node.handle] = [];
      }
      acc[price.node.handle].push(price);
      return acc;
    }, {});
  }, [shopifyPrices]);

  if (!products) {
    return null;
  }

  return (
    <>
      {/* HEADER */}
      <div className={Styles.collection_header}>
        <h1 className="h2 h2--bold h2--uc m-0">{dict.get("SIMILAR RESULTS")}</h1>
        <div className={Styles.collection_header_right}>
          {products && products.length > 0 ? (
            <p className="collection_count subtext dark-grey">
              {`${isMobile && products.length > 42 ? 42 : products.length} ${
                products.length > 0 ? dict.get("products") : dict.get("product")
              }`}
            </p>
          ) : null}
          {!isRobot && (
            <SortsCollection
              onSort={setSortingType}
              sortingType={sortingType}
              className={Styles.collection_header__productsSort}
            />
          )}
        </div>
      </div>
      {/* CONTENT */}
      <div className={`${Styles.content_collection}`}>
        <StickyBox offsetTop={200} offsetBottom={20} className={Styles.filters}>
          <FiltersCollection
            onFilter={(filters) => setActiveFilters(filters)}
            filterStats={filterStats}
            activeFilters={activeFilters}
          />
        </StickyBox>
        <div className={Styles.collection_products}>
          {products
            .filter((p) => p.contentful.node)
            .map(
              (product, i) =>
                ((isMobile && i < 42) || i < rowsPerPage * page * nbProductsByRow) && (
                  <ProductCollection
                    key={`${product.node.id}`}
                    className={`product_collection ${
                      i % 2 === 0 ? "product_collection--left" : "product_collection--right"
                    }`}
                    product={product}
                    collectionHandle={collectionHandle}
                    collectionTitle={collectionHandle}
                    componentType={ComponentType.CP_ADS}
                    orderProduct={i + 1}
                    shouldOrderProducts={shouldOrderProducts}
                    prices={pricesByProduct[product.node.handle]}
                    cpPosition={i + 1}
                  />
                )
            )}
          {!isLastProductDisplayed && (
            <div className={Styles.collection_products__load_more_button_container}>
              <ALButton variant="primary" onClick={() => setPage(page + 1)}>
                VIEW MORE
              </ALButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PdpAdsCollection;
